import Button from '@/components/Button';
import { DEFAULT_PAGE_SIZE, TruentityDataGrid } from '@/components/DataGrid/TruentityDataGrid';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';
import IcdDescriptionDialog from '@/components/Dialogs/IcdDescriptionDialog';
import ScheduledReportsListDialog from '@/components/Dialogs/ScheduledReportsListDialog';
import TruentityDatePicker from '@/components/TruentityDatePicker';
import { H1, H3 } from '@/components/Typography';
import Link from '@/elements/Link';
import type { CreateScheduledReportResponse } from '@/graphql/administration';
import { CREATE_SCHEDULED_REPORT } from '@/graphql/administration';
import type { GetRpmClaimsResponse } from '@/graphql/remotePatientMonitoring';
import { GET_CLAIMS } from '@/graphql/remotePatientMonitoring';
import { color } from '@/styles/assets/colors';
import { MedicalServices } from '@/types/admin';
import { ScheduledReportTypeEnum } from '@/types/administration';
import type { RpmClaimsType } from '@/types/remotePatientMonitoring';
import { getAccountRedirectUrl, getAccountUserFullName } from '@/util/account';
import { currentLoggedUserVar } from '@/util/apollo/cache';
import { checkIfCurrentMonth, formatDate, formatDateIgnoreTZ, formatTime } from '@/util/format';
import { pathnameIncludes } from '@/util/location';
import { getClaimsChipColor } from '@/util/rpm';
import type { ApolloError } from '@apollo/client';
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client';
import type { DateInput } from '@fullcalendar/core';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Chip, IconButton, LinearProgress, Paper, Stack } from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid-pro';
import { useModal } from 'mui-modal-provider';
import { useSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';

const csvHeaders = [
  { label: 'EHR ID', key: 'ehrId' },
  { label: 'DOB', key: 'dob' },
  { label: 'FIRST NAME', key: 'firstName' },
  { label: 'LAST NAME', key: 'lastName' },
  { label: 'TYPE', key: 'type' },
  { label: 'ICD10 CODE', key: 'icd10Code' },
  { label: 'ICD10 DESCRIPTION', key: 'icd10Description' }
];

const RpmClaims = () => {
  const isRpmBillingPage = useMemo(() => pathnameIncludes('/reports/rpm-billing'), []);
  const modifiedCsvHeaders = useMemo(() => {
    if (isRpmBillingPage) {
      csvHeaders.splice(1, 0, { label: 'COMPANY', key: 'company' });
      return csvHeaders;
    } else {
      return csvHeaders;
    }
  }, [isRpmBillingPage]);

  const { showModal } = useModal();
  const currentUser = useReactiveVar(currentLoggedUserVar);
  const { enqueueSnackbar } = useSnackbar();

  const [claimsData, setClaimsData] = useState<RpmClaimsType[]>([]);

  const [totalRowCount, setTotalRowCount] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(0);

  const [monthAndYear, setMonthAndYear] = useState<DateInput>(formatTime(new Date(), 'MMM YYYY'));
  const [fetchDataButtonVisible, setFetchDataButtonVisible] = useState<boolean>(true);
  const [scheduleButtonClicked, setScheduleButtonClicked] = useState<boolean>(false);

  const [getRpmClaims, { data: rpmClaimsData, loading: rpmClaimsLoading }] = useLazyQuery<GetRpmClaimsResponse>(GET_CLAIMS, {
    fetchPolicy: 'cache-and-network'
  });

  const [createScheduledReport, { loading: createScheduledReportLoading }] =
    useMutation<CreateScheduledReportResponse>(CREATE_SCHEDULED_REPORT);

  const callClaimsQuery = async (isExport: boolean) => {
    await getRpmClaims({
      variables: {
        monthYear: formatTime(monthAndYear, 'MMM YYYY'),
        pageNum: isExport ? 1 : currentPage + 1,
        pageSize: isExport ? 100 : DEFAULT_PAGE_SIZE,
        isExport: isExport,
        all: isRpmBillingPage
      }
    }).catch((error: ApolloError) => {
      console.error(error);
    });
  };

  const onFetchExportButtonClicked = () => {
    setFetchDataButtonVisible(false);
    exportDataToCsv();
  };

  const exportDataToCsv = () => {
    callClaimsQuery(true);
  };

  const handleMonthYear = monthYear => {
    setMonthAndYear(formatTime(monthYear, 'MMM YYYY'));
  };

  const getRpmClaimsDataFiltered = () => {
    const DEFAULT_STRING = 'Not Provided';
    const includeCompany = isRpmBillingPage;

    return claimsData?.map(data => {
      const baseData = {
        ehrId: data?.ehrId || DEFAULT_STRING,
        dob: data?.account.birthDate || DEFAULT_STRING,
        firstName: data?.account?.user.firstName || DEFAULT_STRING,
        lastName: data.account?.user.lastName || DEFAULT_STRING,
        type: data?.cptCode && data?.description ? `${data.cptCode} - ${data.description}` : DEFAULT_STRING,
        icd10Code: data?.diagnosesCodes || DEFAULT_STRING,
        icd10Description: data?.diagnosesNames || DEFAULT_STRING
      };

      if (includeCompany) {
        return {
          ...baseData,
          company: data?.relyingParty.name || DEFAULT_STRING
        };
      }

      return baseData;
    });
  };

  const renderIcd10Values = useCallback(({ value }) => {
    const values = value.split(', ');
    const displayValues = values.slice(0, 2).map((val, index) => (
      <Box key={index} textTransform="capitalize" sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
        {val}
      </Box>
    ));

    return (
      <Stack direction="column" spacing={0.5} sx={{ width: '100%' }}>
        {displayValues}
      </Stack>
    );
  }, []);

  const showIcdDescriptionModal = (icdCodes: string, icdNames: string) => {
    const modalRef = showModal(IcdDescriptionDialog, {
      title: 'ICD10 Codes and Descriptions',
      hideDialog: () => {
        modalRef.hide();
      },
      diagnosesCodes: icdCodes,
      diagnosesNames: icdNames
    });
  };

  const Icd10CodeRenderer = ({ icdCodes, icdNames }) => {
    const codes = icdCodes.split(',');
    const hasMoreCodes = codes.length > 2;

    return (
      hasMoreCodes && (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Box>+ {`+ ${codes.length - 2}`}</Box>
          <IconButton onClick={() => showIcdDescriptionModal(icdCodes, icdNames)}>
            <ChevronRightIcon />
          </IconButton>
        </Stack>
      )
    );
  };

  const generateFilename = (currentUser, monthAndYear) => {
    const relyingPartyName = currentUser?.relyingParty?.name ?? '';
    const formattedDate = formatTime(monthAndYear, 'MMM, yyyy');

    if (isRpmBillingPage) {
      return `RPM Claims - ${formattedDate}.csv`;
    } else {
      return `${relyingPartyName} - RPM Claims - ${formattedDate}.csv`;
    }
  };

  const scheduleRpmBillingReport = async () => {
    try {
      const response = await createScheduledReport({
        variables: {
          reportType: isRpmBillingPage ? ScheduledReportTypeEnum.ALL_RPM_BILLING_REPORT : ScheduledReportTypeEnum.RPM_BILLING_REPORT,
          scheduledReportInput: {
            filterMonthYear: formatTime(monthAndYear, 'MMM YYYY')
          }
        }
      });

      if (response?.data?.createScheduledReport?.status === 'Success') {
        enqueueSnackbar('RPM Billing Report scheduled successfully', { variant: 'success' });
      } else {
        enqueueSnackbar('Failed to schedule RPM Billing Report', { variant: 'error' });
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Failed to schedule RPM Billing Report', { variant: 'error' });
    }
  };

  const handleScheduleReport = async () => {
    const modal = showModal(ConfirmDialog, {
      title: 'Confirm Schedule Report',
      message: `This will schedule to ${
        (claimsData?.length ?? 0) > 0 ? 'regenerate' : 'generate'
      } data and RPM Billing Report for the month year: <b>${monthAndYear}</b>. Are you sure you want to proceed?`,
      onAgree: async () => {
        if (checkIfCurrentMonth(monthAndYear)) {
          setScheduleButtonClicked(true);
        }
        await scheduleRpmBillingReport();
        modal.hide();
      },
      onDisagree: () => {
        modal.hide();
      },
      maxWidth: 'md'
    });
  };

  const handleViewScheduledReports = () => {
    const modal = showModal(ScheduledReportsListDialog, {
      hideDialog: () => {
        modal.hide();
      },
      reportType: isRpmBillingPage ? ScheduledReportTypeEnum.ALL_RPM_BILLING_REPORT : ScheduledReportTypeEnum.RPM_BILLING_REPORT,
      title: 'Scheduled Reports'
    });
  };

  useEffect(() => {
    if (rpmClaimsData?.getRpmClaims?.billingAccounts) {
      setClaimsData(rpmClaimsData?.getRpmClaims?.billingAccounts ?? []);
      setTotalRowCount(rpmClaimsData?.getRpmClaims?.meta?.totalCount || 0);
    }
  }, [rpmClaimsData]);

  useEffect(() => {
    callClaimsQuery(false);
    setFetchDataButtonVisible(true);
  }, [monthAndYear, currentPage]);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'ehrId',
        headerName: 'EHR Id',
        flex: 1,
        maxWidth: 150,
        headerAlign: 'center',
        align: 'center',
        valueGetter: params => params.row.ehrId ?? '',
        sortable: false
      },
      {
        field: 'patientName',
        headerName: 'Patient Name',
        sortable: true,
        flex: 1,
        align: 'left',
        maxWidth: 250,
        valueGetter: params => getAccountUserFullName(params.row.user),
        renderCell: params => {
          const name = getAccountUserFullName(params.row.account.user);
          const status = params.row.account.rpmStatus;
          const accountMonitoringDevices = params.row.account.accountsMonitoringDevices;
          const relying_party_id = params.row?.relyingParty.id;

          return (
            <Stack spacing={3} direction="row" sx={{ width: '100%', userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              {currentUser?.relyingParty.id === relying_party_id ? (
                <Link
                  to={getAccountRedirectUrl(params?.row?.account.truentityId, MedicalServices.RPM, status, accountMonitoringDevices) ?? ''}
                >
                  {name}
                </Link>
              ) : (
                <span>{name}</span>
              )}
            </Stack>
          );
        }
      },
      {
        field: 'company',
        headerName: 'Company',
        flex: 1,
        maxWidth: 200,
        valueGetter: params => params.row.relyingParty?.name || 'Not Provided',
        sortable: false
      },
      {
        field: 'dateOfBirth',
        headerName: 'Date of Birth',
        flex: 1,
        maxWidth: 150,
        valueGetter: params => formatDateIgnoreTZ(params.row.account.birthDate) ?? '',
        sortable: false
      },
      {
        field: 'dateEnrolled',
        headerName: 'Date Enrolled',
        sortable: true,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        type: 'date',
        valueGetter: params => {
          const rpmEnrolledAt = params.row.account?.rpmEnrolledAt;
          return rpmEnrolledAt ? new Date(rpmEnrolledAt) : null;
        },
        valueFormatter: params => (params?.value ? formatDate(params?.value) : '-')
      },
      {
        field: 'type',
        headerName: 'Type',
        flex: 1,
        headerAlign: 'center',
        align: 'center',
        minWidth: 300,
        renderCell: params => {
          const { cptCode, description } = params.row;
          const { backgroundColor, textColor } = getClaimsChipColor(cptCode);

          return (
            <Stack direction="row" sx={{ userSelect: 'none' }} alignItems="start" justifyContent={'start'}>
              <Chip label={`${cptCode} - ${description}`} sx={{ backgroundColor: backgroundColor, color: textColor }} />
            </Stack>
          );
        }
      },
      {
        field: 'icd10Code',
        headerName: 'ICD10 Code',
        flex: 1,
        maxWidth: 100,
        renderCell: params => renderIcd10Values({ value: params.row.diagnosesCodes || '' }),
        sortable: false
      },
      {
        field: 'action',
        headerName: '',
        sortable: false,
        flex: 1,
        maxWidth: 20,
        filterable: false,
        pinnable: false,
        align: 'center',
        headerAlign: 'left',
        renderCell: params => Icd10CodeRenderer({ icdCodes: params.row.diagnosesCodes || '', icdNames: params.row.diagnosesNames || '' })
      },
      {
        field: 'icd10Description',
        headerName: 'ICD10 Description',
        flex: 2,
        renderCell: params => renderIcd10Values({ value: params.row.diagnosesNames || '' }),
        sortable: false
      }
    ],
    [currentUser]
  );

  return (
    <Stack>
      <Stack
        sx={{
          padding: 3,
          backgroundColor: color.paper,
          borderRadius: '8px',
          marginBottom: 3
        }}
        spacing={2}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
        direction={'column'}
      >
        <Stack direction="row" width="100%" height="auto" justifyContent="space-between" alignItems="center" spacing={2}>
          <H1 textAlign="left" sx={{ flex: 4 }}>
            {`${isRpmBillingPage ? 'All RPM Claims' : 'RPM Claims'}`}
          </H1>
          <Stack direction="row" width="auto" justifyContent="flex-end" alignItems="center" spacing={1}>
            <TruentityDatePicker
              showMonth={true}
              showYear={true}
              value={monthAndYear}
              onChange={handleMonthYear}
              views={['month', 'year']}
              sx={{ width: '415px' }}
            />
            <Button isLoading={createScheduledReportLoading} variant="contained" color="primary" onClick={handleScheduleReport}>
              Schedule Report
            </Button>
          </Stack>
        </Stack>
        <Stack direction="row" width="100%" height="auto" justifyContent="flex-end" alignItems="center">
          <Button
            type="button"
            variant="outlined"
            size="small"
            onClick={handleViewScheduledReports}
            isLoading={createScheduledReportLoading}
          >
            View Scheduled Reports
          </Button>
        </Stack>
      </Stack>

      <Stack width="100%" flexDirection="column" justifyContent="stretch" alignItems="stretch">
        {checkIfCurrentMonth(monthAndYear) && claimsData?.length === 0 && !scheduleButtonClicked ? (
          <Paper>
            <Stack alignItems="center" justifyContent="center" height="50vh" textAlign="center">
              <H3 sx={{ color: color.grey500, lineHeight: 1, marginBottom: 1 }}>The data for the current month has not been generated.</H3>
              <H3 sx={{ color: color.grey500, lineHeight: 1 }}>
                Click the following button to schedule report for the current month and generate data.
              </H3>
              <Button variant="contained" color="primary" sx={{ marginTop: 3 }} onClick={handleScheduleReport}>
                Schedule Report
              </Button>
            </Stack>
          </Paper>
        ) : (
          <Paper
            padding={2}
            elevation={0}
            component={Stack}
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
          >
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
              {fetchDataButtonVisible ? (
                <>
                  <Button
                    title=""
                    variant="outlined"
                    startIcon={<RefreshIcon />}
                    onClick={() => callClaimsQuery(false)}
                    disabled={rpmClaimsLoading}
                  >
                    Refresh
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    disabled={claimsData?.length === 0}
                    onClick={() => {
                      onFetchExportButtonClicked();
                    }}
                  >
                    Load for Export
                  </Button>
                </>
              ) : (
                <Button isLoading={rpmClaimsLoading} color="primary" variant="contained" size="small">
                  {(claimsData?.length ?? 0) > 0 && (
                    <CSVLink
                      headers={modifiedCsvHeaders}
                      data={getRpmClaimsDataFiltered()}
                      filename={generateFilename(currentUser, monthAndYear)}
                      style={{ textDecoration: 'none', color: '#fff' }}
                    >
                      {rpmClaimsLoading ? 'Loading CSV...' : 'Export'}
                    </CSVLink>
                  )}
                </Button>
              )}
            </Stack>
            <Stack flex={1}>
              <TruentityDataGrid
                name={`${isRpmBillingPage ? 'all' : 'company'}-rpm-claims`}
                autoHeight
                rows={claimsData}
                rowCount={totalRowCount}
                paginationModel={{ pageSize: DEFAULT_PAGE_SIZE, page: currentPage }}
                onPaginationModelChange={({ page }) => {
                  setCurrentPage(page);
                }}
                slots={{
                  loadingOverlay: LinearProgress
                }}
                columnVisibilityModel={{
                  company: isRpmBillingPage
                }}
                loading={rpmClaimsLoading}
                paginationMode="server"
                columns={columns}
                disableRowSelectionOnClick
                sx={{ backgroundColor: '#ffffff' }}
              />
            </Stack>
          </Paper>
        )}
      </Stack>
    </Stack>
  );
};

export default RpmClaims;
